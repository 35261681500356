module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"undefined","siteUrl":"https://doriswarren.com","matomoUrl":"undefined","localScript":"/piwik.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"doris warren","short_name":"mk","start_url":"/","background_color":"#e7eef4","theme_color":"#e7eef4","icon":"src/images/favicon-butterfly.png","display":"standalone","cache_busting_mode":"name","theme_color_in_head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
